import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import React from "react";
import offerDescription from "../OfferDescription.jsx";


export default function Firmenyoga() {
  /*  const [matches, setMatches] = useState(window.innerWidth);
    window.addEventListener("resize", checkSize);

    function checkSize() {
        setMatches(window.innerWidth);
    }*/
    return (
        <>
            <section className="offerSec">
                <h4 className="introHeader">{offerDescription[0].title}</h4>
                <div className="intro">{offerDescription[0].body}</div>
                <h2 id="offer">Angebot</h2>
                <br/>
                <Row lg={2} sm={12} className="g-4">
                    <Col>
                        <Card className="cardOfferHeight">
                            <Card.Body>
                                <Card.Title>Vinyasa Flow Yoga</Card.Title>
                                <br></br>

                                <Card.Text>
                                    Vinyasa Flow Yoga ist eine Form des Hatha Yoga und basiert auf der Verbindung von Atem und Bewegung.
                                    <br/>
                                    Die Asanas (Körperhaltungen) werden fließend miteinander verbunden, der Atem initiiert die Bewegung.
                                    <br/>
                                    Durch die Vinyasa Praxis erhöhen wir den Energiefluss im System und sprechen die Muskulatur an. Es entsteht ein Flow, eine kraftvolle und zugleich entspannende Praxis.
                                    <br/>
                                    Zur Kräftigung werden manche Asanas länger gehalten.
                                    <br/>
                                    Anders als im Sport geht es darum, Körper und Geist in Einklang zu bringen und so ein Gefühl der Ausgeglichenheit und Entspannung zu erschaffen.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="cardOfferHeight">
                            <Card.Body>
                                <Card.Title>Yin Yoga</Card.Title>
                                <br></br>
                                <Card.Text>
                                    Yin Yoga ist eine sanfte Yoga-Praxis, die tiefe Entspannung sowie Flexibilität fördert.
                                    <br/>
                                    Im Gegensatz zu dynamischen Yoga-Stilen werden beim Yin Yoga die Asanas (Körperhaltungen) passiv und ohne Muskelkraft für längere Zeit gehalten. Dadurch werden tiefere Gewebeschichten wie das Bindegewebe, die Faszien und die Gelenke angesprochen.
                                    <br/>
                                    Für das Wohlbefinden bringt Yin Yoga viele Vorteile: Die ruhigen, meditativen Haltungen helfen, Stress abzubauen und die Gedanken zu beruhigen.
                                    <br/>
                                    Körperlich unterstützt Yin Yoga die Mobilität und Flexibilität, was besonders hilfreich für Menschen mit sitzenden Tätigkeiten ist. Es stärkt zudem die Gelenke und verbessert die Durchblutung.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/*{matches > 768 && (
                        <Col>
                            <Card.Img className="cardImageDetail" variant="top" src={OfferImg1} />
                        </Col>
                    )}*/}
                </Row>
                <LinkContainer to={`/`}>
                    <Button>Zurück</Button>
                </LinkContainer>
            </section>
        </>
    );
}
