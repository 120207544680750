import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import React from "react";
import OfferImg4 from "../../assets/freepik-frau-rumpfbeugehaltung-steht.jpeg";

import offerDescription from "../OfferDescription.jsx";

export default function Popup() {
   /* const [matches, setMatches] = useState(window.innerWidth);
    window.addEventListener("resize", checkSize);

    function checkSize() {
        setMatches(window.innerWidth);
    }*/
    return (
        <>
            <section className="offerSec">
                <h4 className="introHeader">{offerDescription[0].title}</h4>
                <div className="intro">{offerDescription[0].body}</div>
                <h2 id="offer">Angebot</h2>
                <br/>
                <br/>
             <Row lg={1} sm={12} className="g-4">
                        <Col>
                            <Card className="cardOffer">
                                <Card.Img className="cardImageDetailPopup" variant="top" src={OfferImg4} />
                                <Card.Body>
                                    <Card.Title>VHS Landsberg</Card.Title>
                                    <Card.Subtitle>
                                        4 Termine Freitags  14.03., 21.03., 04.04., 11.04.25
                                        09.00 – 10.00 Uhr
                                        <br></br>
                                        <br></br>
                                    </Card.Subtitle>
                                    <Card>
                                        <Card.Header>09.00 – 10.00 Uhr</Card.Header>
                                        <Card.Body>
                                            <Card.Title>Vinyasa Flow Yoga</Card.Title>
                                            <Card.Text>Ohne oder wenig Vorkenntnisse<br/><br/>
                                                Du benötigst eine Matte und eine Decke
                                            </Card.Text>
                                            <Card.Link href="https://www.vhs-landsberg.de/kurssuche/kurs/Vinyasa-Flow-Yoga/B3520" rel="noreferrer" target="_blank">Anmelden</Card.Link>
                                        </Card.Body>
                                    </Card>
                                    <br></br>
                                    {/*<Card>
                                        <Card.Header>10.15 – 11.15 Uhr</Card.Header>
                                        <Card.Body>
                                            <Card.Title>Yin Yoga</Card.Title>
                                            <Card.Text>Ohne oder wenig Vorkenntnisse<br/><br/>
                                                Du benötigst eine Matte und eine Decke
                                            </Card.Text>
                                            <Card.Link href="https://www.vhs-landsberg.de/kurssuche/kurs/Yin-Yoga/B3521" rel="noreferrer" target="_blank">Anmelden</Card.Link>
                                        </Card.Body>
                                    </Card>*/}
                                </Card.Body>
                            </Card>
                        </Col>

                 <Col>
                            <Card className="cardOffer">
                                {/*<Card.Img className="cardImageDetailPopup" variant="top" src={OfferImg5} />*/}
                                <Card.Body>
                                    <Card.Title>Yoga am Morgen</Card.Title>
                                    <Card.Text>
                                        <br></br>
                                        <br></br>
                                        Vinyasa Flow Yoga
                                        <br></br>
                                        Wir üben fliessend, kräftigend, ausgleichend.
                                        <br></br>
                                        <br></br>
                                        Samstags 09.00 - 10.00
                                        <br></br><br></br>
                                        <strong>Mit Yoga den Tag beginnen - dehnen, bewegen, neue Energie aufnehmen.</strong>
                                        <br></br>
                                        Wir üben fliessend, kräftigend, ausgleichend.
                                        <br></br><br></br>
                                        Du benötigst bequeme, bei Bedarf warme Kleidung und eine Matte.
                                        <br></br><br></br>
                                        1 Termin 	18,00 Euro<br></br>
                                        3 Termine 	50,00 Euro<br></br>
                                        5 Termine 	80,00 Euro<br></br>
                                        <br></br>
                                        <br></br>
                                        Probetermin 15,00 Euro
                                        <br></br><br></br>
                                        Ort: AWO Begegnungsstätte, Lechstraße 5, Landsberg am Lech
                                        <br></br>
                                        <br></br>
                                        Anmeldung bitte bis 24 Stunden vorher:&nbsp;
                                        <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage PopUp Yoga">kontakt@sylkeheuer.de</a>
                                        <br></br>
                                        <br></br>
                                        Bei weniger als 4 Anmeldungen kann ein Termin abgesagt werden.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
             </Row>
                 <LinkContainer to={`/`}>
                     <Button>Zurück</Button>
                 </LinkContainer>
            </section>
        </>
    );
}
