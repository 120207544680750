import "./index.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./components/Navigation.jsx";
import Header from "./components/Header.jsx";
import AboutMe from "./components/AboutMe.jsx";
import OfferRoutes from "./components/Offer.jsx";
import ContactLink from "./components/ContactLink.jsx";
import ScrollButton from "./components/ScrollButton.jsx";
import Footer from "./components/Footer.jsx";
import Cookies from "./components/Cookies";
import Newsblog from "./components/Newsblog";


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
        <Header />
        <ScrollButton />
        <Newsblog />
      </header>
      <main>
        <OfferRoutes />
        <AboutMe />
        <ContactLink />
      </main>
        <Cookies />
      <Footer />
    </div>
  );
}

export default App;
