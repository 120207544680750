
import "./Footer.css";
import Modal from "react-bootstrap/Modal";
import {useState} from "react";
import {Container} from "react-bootstrap";

function Footer() {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <Container className="container">
            <p className="impBtn" onClick={handleShow}>
                Impressum/Datenschutz
            </p>

            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Impressum</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h4>Dienstanbieter</h4>

                    <div>
                        <p>Sylke Heuer</p>
                        <p>Römerauterrasse 36 a </p>
                        <p>86899 Landsberg am Lech</p>
                        <p>Deutschland</p>
                    </div>

                    <h4>Kontaktmöglichkeiten</h4>
                    <div>
                        <p>Email-Adresse: kontakt@sylkeheuer.de</p>
                        <p>Telefon: 08191-4010960</p>
                    </div>

                    <h4>Berufshaftpflichtversicherung</h4>
                    <div>
                        <p>Debeka Allgemeine Versicherung AG</p>
                        <p>56058 Koblenz</p>
                    </div>

                    <h4>Online-Streitbeilegung (OS)</h4>
                    <div>
                        <p>Online-Streitbeilegung: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter https://ec.europa.eu/consumers/odr/ finden. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu nutzen.</p>
                    </div>
                    <h4>Haftungs- und Schutzrechtshinweise</h4>
                    <div>
                        <p>Haftungsausschluss: Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB oder verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.
                        </p>
                        <p>Links auf fremde Webseiten: Die Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen, liegen außerhalb unseres Verantwortungsbereiches und wir machen sie uns nicht zu Eigen. Für alle Inhalte und Nachteile, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren Informationen entstehen, übernehmen wir keine Verantwortung.</p>
                        <p>Urheberrechte und Markenrechte: Alle auf dieser Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteinhaber.</p>
                        <p>Hinweise auf Rechtsverstöße: Sollten Sie innerhalb unseres Internetauftritts Rechtsverstöße bemerken, bitten wir Sie uns auf diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach Kenntnisnahme unverzüglich entfernen.</p>
                    </div>

                    <h4>Bildnachweise</h4>
                    <div>
                        <p>Bildquellen und Urheberrechtshinweise:</p>
                        <p>Bildquellen Copyright Sylke Heuer</p>
                        <p>Jessica P. Pixabay - h.hach pixabay Frosch weiß</p>
                        <p>shusipu/pixabay</p>
                        <p>Freepik Kind das auf der Matte meditiert</p>
                        <p>Freepik Frau Rumpfbeugehaltung steht</p>
                        <p>Freepik yoga floor</p>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );

}


export default Footer;
